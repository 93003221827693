import { Text, Link } from '@cash-me/react-components'

import { ItemType } from 'modules/help'

import { List, ListItem } from './styles'

export const accordion = [
  {
    id: 'faq-0',
    title: 'A CashMe é uma administradora de Consórcio?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: `Não, mas oferece essa solução. A CashMe é a Fintech do grupo Cyrela e oferece diversos 
        produtos financeiros, como o consórcio, para impulsionar sonhos, projetos e pessoas em todo Brasil.`
      }
    ]
  },
  {
    id: 'faq-1',
    title: 'O consórcio é um bom investimento?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: `Sim, o consórcio é um bom investimento para quem busca uma maneira disciplinada de 
        adquirir um bem e está disposto a aguardar a contemplação. Essa modalidade de crédito dá ao 
        cliente diversos benefícios, como a isenção da cobrança de juros, liberdade para escolher 
        como usar a carta de crédito (desde que a escolha esteja dentro das regras do grupo - o 
        crédito poderá ser utilizado na compra de qualquer um dos bens do nosso amplo portfólio), 
        planejamento financeiro (sem descapitalização), uso do FGTS como parte do pagamento (no caso de 
        imóveis), isenção de Imposto de Renda sobre os valores pagos ou recebidos, entre outros. Na 
        CashMe, não é necessário pagar taxa de adesão para iniciar um consórcio. Além disso, não há 
        fundo de reserva, tornando o processo mais acessível e transparente.`
      }
    ]
  },
  {
    id: 'faq-2',
    title: 'Como ser contemplado no consórcio?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: `A contemplação pode ocorrer de duas maneiras: por sorteio ou por lance. No sorteio, 
        que acontece mensalmente, todos concorrem de forma igualitária, enquanto no lance, os próprios 
        participantes oferecem valores adicionais para antecipar suas contemplações, similar a um leilão. 
        No final, todos os membros do grupo terão sido beneficiados pela carta de crédito do consórcio.`
      }
    ]
  },
  {
    id: 'faq-3',
    title: 'Como funciona o lance no consórcio?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: 'Os lances no consórcio são formas de antecipar sua contemplação. Veja como funciona:'
      },
      {
        id: '2',
        type: 'node' as ItemType,
        content: (
          <>
            <List>
              <ListItem>
                <b>Modalidades:</b> Existem duas maneiras de ofertar um lance, via lance livre e lance 
                fixo. No lance livre, o valor do lance não é pré-definido, já no lance fixo, os valores 
                mínimo e máximo são pré-definidos.  
              </ListItem>
              <ListItem>
                <b>Formas de pagamento:</b> Podem ser feitas de duas formas, utilizando recursos próprios 
                ou o lance embutido. Ao optar pelo lance embutido, parte do crédito é descontado da sua 
                carta de crédito.
              </ListItem>
              <ListItem>
                <b>Importante saber:</b> Os valores do lances abatem as parcelas futuras, podendo 
                reduzir o prazo do consórcio ou diminuir as parcelas restantes. 
              </ListItem>
            </List>
          </>
        )
      },
      {
        id: '3',
        type: 'text' as ItemType,
        content: `Na CashMe, entendemos que as estratégias por trás dos lances fazem toda a diferença 
        para a contemplação. Por isso, o lance pode ser parcelado em até 4x!`
      },
    ]
  },
  {
    id: 'faq-4',
    title: 'O que é o Plano Pontual?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: `Clientes CashMe têm a opção de aderir ao Plano Pontual, um pacote exclusivo para 
        acelerar a conquista. Isso significa que, dependendo do grupo em que estiver, poderá resgatar 
        seu bem após alguns meses, sem depender de lances. O Plano Pontual oferece uma contemplação 
        programada!`
      },
      {
        id: '2',
        type: 'node' as ItemType,
        content: (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)" weight="bold">
              Plano Pontual Automóveis
            </Text>
            <List>
              <ListItem>
                Prazo de 60 meses com 120 cotas disponíveis
              </ListItem>
              <ListItem>
                Taxas de 21,5% a 23,5%
              </ListItem>
              <ListItem>
                Créditos variando de 35k a 440k
              </ListItem>
              <ListItem>
                Até 2 sorteios mensais
              </ListItem>
              <ListItem>
                O cliente pode antecipar seu automóvel a partir da 6ª parcela (contanto que tenha pagado 
                24 parcelas no total, ou seja, mais 18 adiantadas)
              </ListItem>
            </List>
          </>
        )
      },
      {
        id: '3',
        type: 'node' as ItemType,
        content: (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)" weight="bold">
              Plano Pontual Imóveis
            </Text>
            <List>
              <ListItem>
                Prazo de 120 meses com 240 cotas disponíveis
              </ListItem>
              <ListItem>
                Taxa de 26,5%
              </ListItem>
              <ListItem>
                Créditos variando de 200k a 400k
              </ListItem>
              <ListItem>
                Média de 2 sorteios mensais
              </ListItem>
              <ListItem>
                O cliente pode antecipar seu imóvel a partir da 12ª parcela (contanto que tenha pagado 
                48 parcelas no total, ou seja, mais 36 adiantadas)
              </ListItem>
            </List>
          </>
        )
      }
    ]
  },
  {
    id: 'faq-5',
    title: 'O que é o Prêmio Pontualidade?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: `Na CashMe, a partir da segunda parcela paga, os clientes que estiverem em dia com 
        seus pagamentos ganham mensalmente dois números da sorte para concorrer ao sorteio de prêmios 
        em dinheiro. O valor não está atrelado às cotas, sendo apenas um bônus que o cliente poderá 
        utilizar como quiser. É necessário que o consorciado tenha o Seguro Prestamista ativo em sua 
        cota.`
      }
    ]
  },
  {
    id: 'faq-6',
    title: 'Como acessar as informações da minha cota? E os resultados das assembleias? ',
    item: [
      {
        id: '1',
        type: 'node' as ItemType,
        content: (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              As informações sobre a cota podem ser consultadas clicando em {' '}
              <Link href="https://autoatendimentoconsorcio.com.br/autoatendimentoweb">
                <Text as="span" size="body2" color="var(--colors-primary-background-strong-default)">
                  portal de atendimento
                </Text>
              </Link>
              . 
              Lá, os clientes poderão ofertar lances, ver os resultados de assembleias e acompanhar os 
              pagamentos do seu consórcio.
            </Text>
          </>
        )
      },
      {
        id: '2',
        type: 'node' as ItemType,
        content: (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Já o passo a passo para a liberação da carta de crédito para a aquisição do bem 
              poderá ser acessado clicando em {' '}
              <Link href="https://credita.rodobens.com.br/login">
                <Text as="span" size="body2" color="var(--colors-primary-background-strong-default)">
                  área do contemplado
                </Text>
              </Link>
              .
            </Text>
          </>
        )
      }
    ]
  },
  {
    id: 'faq-7',
    title: 'Como as parcelas do consórcio são compostas e quando são reajustadas?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: `No consórcio, as parcelas que o cliente paga todo mês são calculadas com base no 
        valor do crédito que ele escolheu, mais a taxa de administração. Essa taxa é cobrada para 
        cobrir os custos de gerenciar o grupo de pessoas que estão no consórcio, e pode incluir o 
        seguro prestamista, caso ele tenha optado por este serviço.`
      },
      {
        id: '2',
        type: 'text' as ItemType,
        content: `As parcelas podem ser reajustadas ao longo do tempo, dependendo das definições do 
        grupo. Esse ajuste pode acontecer de acordo com a tabela de preços do fabricante do bem ou 
        seguindo índices de inflação, como o IPCA (Índice do Preço ao Consumidor Amplo), o INCC 
        (Índice Nacional do Custo da Construção) ou o INPC (Índice Nacional do Preço ao Consumidor).`
      }
    ]
  },
  {
    id: 'faq-8',
    title: 'Ao cancelar o consórcio, quando terei a devolução dos valores pagos?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: `A devolução dos valores pagos é feita quando a cota for contemplada por sorteio ou 
        em até 60 dias após a finalização do grupo. Dos valores devolvidos são descontados: taxa de 
        administração (do período da cota ativa) e multa por quebra de contrato.`
      }
    ]
  }
]

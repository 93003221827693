import styled from 'styled-components'

import { theme } from 'styles/theme'

const { space, colors, fontSizes, lineHeights } = theme

export const List = styled.ul`
  padding-bottom: 0 !important;
`

export const ListItem = styled.li`
  margin-bottom: ${space['spacing-md']};
  font-size: ${fontSizes['font-size-body-2']};
  color: ${colors['neutral-text-weak']};
  line-height: ${lineHeights['font-line-height-lg']};

  &::before {
    content: '●';
    display: inline-block;
    vertical-align: text-bottom;
    padding-right: ${space['spacing-xxs']};
    font-size: ${fontSizes['font-size-caption']};
    color: ${colors['neutral-text-weak']};
  }
`
